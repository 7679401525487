import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  Timestamp,
  where,
} from 'firebase/firestore';

import { db } from './firebase';

const LIMIT = 5;

export default {
  /**
   * this function will be fired when the app is first time run,
   * and it will fetch first 5 _data, here i retrieve them in desc order,
   * until show last added post first.
   */
  async dataFirstBatch(COLLECTION = 'drivers', SEARCH_TERM = '') {
    try {

      let q;

      if (SEARCH_TERM !== '') {
        console.log('SEARCH_TERM', SEARCH_TERM)
        q = query(
          collection(db, COLLECTION),
          where('full_name', '>=', SEARCH_TERM),
          where('full_name', '<=', SEARCH_TERM + '\uf8ff'),
          orderBy('full_name', 'asc'),
          limit(LIMIT)
        );
      }
      else {
        q = query(
          collection(db, COLLECTION),
          orderBy('account_created_at', 'desc'),
          limit(LIMIT)
        );
      }


      const data = await getDocs(q);


      const _data = [];
      let lastKey = '';
      data.forEach((doc) => {
        _data.push({ id: doc.id, ...doc.data() });
        if (SEARCH_TERM !== '') {
          lastKey = [doc.data().full_name, doc.data().user_id];
        }
        else {
          lastKey = doc.data().account_created_at;
        }
      });

      return { data: _data, lastKey };
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * this function will be fired each time the user click on 'More _data' button,
   * it receive key of last post in previous batch, then fetch next LIMIT _data
   * starting after last fetched post.
   */
  dataNextBatch: async (COLLECTION = 'drivers', key, SEARCH_TERM = '') => {
    console.log('key[0]', key[0]);
    console.log('key[1]', key[1]);
    // console.log(key[0]);
    // console.log(key[1]);
    try {
      let q;
      if (SEARCH_TERM !== '') {
        q = query(
          collection(db, COLLECTION),
          where('full_name', '>=', SEARCH_TERM),
          where('full_name', '<=', SEARCH_TERM + '\uf8ff'),
          orderBy('full_name', 'asc'),
          orderBy('user_id', 'asc'),
          startAfter(key[0], key[1]),
          limit(LIMIT)
        );
      }
      else {
        q = query(
          collection(db, COLLECTION),
          orderBy('account_created_at', 'desc'),
          startAfter(new Timestamp(key.seconds, key.nanoseconds)),
          limit(LIMIT)
        );

      }


      const data = await getDocs(q);

      const _data = [];
      let lastKey = '';
      data.forEach((doc) => {
        _data.push({ id: doc.id, ...doc.data() });
        if (SEARCH_TERM !== '') {
          lastKey = [doc.data().full_name, doc.data().user_id];
        }
        else {
          lastKey = doc.data().account_created_at;
        }
      });
      return { data: _data, lastKey };
    } catch (e) {
      console.log(e);
    }
  },
};
