import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import dayjs from 'dayjs';
import GoogleMapReact from 'google-map-react';
// components
import Layout from '../components/layout';
import Toolbar from '../components/toolbar';
import Table from '../components/table';
import Chip from '../components/chips';
import Modal from '../components/modal';
import Loading from '../components/loading';
import Input from '../components/input';
import Label from '../components/label';
import Button from '../components/button';
// import SubscriptionModal from './drivers/subscriptionModal';
import Lightbox from '../components/lightbox';
import { collection, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../helpers/firebase';
import paginatedFetch from '../helpers/paginatedFetch';
import { ToastContainer, toast } from 'react-toastify';



const Drivers = (params) => {
  const [showModal, setShowModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [image, setImage] = useState(null);
  const [driverData, setDriverData] = useState([]);
  const [data, setData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(dayjs());
  const [updateCounter, setUpdateCounter] = useState(0);
  const [lastKey, setLastKey] = useState('');
  const [nextDataLoading, setNextDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getInitialData = () => {
    paginatedFetch
      .dataFirstBatch('drivers', searchValue)
      .then((res) => {
        setData(res.data);
        // console.log(res.lastKey);
        console.log('res.lastKey :>> ', res.lastKey);
        setLastKey(res.lastKey);
        setLastUpdated(dayjs());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInitialData();
  }, [searchValue]);


  useEffect(() => {
    // console.log('data changed this happened');
    const getDriverData = () => {
      if (!nextDataLoading) {

        const _data = data && data.map(data => {
          const { mobile_number, full_name, is_verified, user_id } = data;

          const status = (is_verified) => {
            // console.log('is_verified :>> ', is_verified);
            if (is_verified == 'pending') {

              return {
                text: 'pending',
                type: 'warning',
                showAsChip: true,

              }
            }
            else if (is_verified) {
              return {
                text: 'verified',
                type: 'success',
                showAsChip: true,

              }
            }
            else {
              return {
                text: 'unverified',
                type: 'danger',
                showAsChip: true,

              }
            }
          }

          // console.log('status :>> ', status);
          return {
            id: user_id,
            full_name,
            mobile_number,
            status: status(is_verified)
          }
        });

        setDriverData(_data);
      }
    }
    getDriverData();
    console.log(data.length);
  }, [data, nextDataLoading])

  useEffect(() => {
    const timer = setInterval(() => setUpdateCounter(updateCounter + 1), 5000);
    return () => clearInterval(timer);
  }, [updateCounter]);

  const fetchMoreData = () => {
    if (lastKey.hasOwnProperty('seconds') || lastKey.length > 0) {
      setNextDataLoading(true);
      paginatedFetch
        .dataNextBatch('drivers', lastKey, searchValue)
        .then((res) => {
          setLastKey(res.lastKey);
          setData(data.concat(res.data));
          setNextDataLoading(false);
          setLastUpdated(dayjs());
        })
        .catch((err) => {
          console.log(err);
          setNextDataLoading(false);
        });
    }
  };


  const tableHeaders = [
    { name: 'Name' },
    { name: 'Mobile Number' },
    { name: 'Status' },
    { name: 'Actions' },
  ];




  // table data

  const handleModalOpen = async (id) => {
    setShowModal(true);

    console.log('id :>> ', id);

    const docSnap = await getDoc(doc(db, 'drivers', id));
    if (!docSnap.exists()) {
      alert('Driver doesn\'t exist!')
    } else {
      setModal({ id: docSnap.id, ...docSnap.data() });
      console.log(docSnap.data());
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModal(null);
  };

  const handleSubscriptionOpen = async (id) => {
    setShowSubscriptionModal(id);
  }


  const handleVerify = (id) => {
    const driverRef = doc(db, 'drivers', id);
    updateDoc(driverRef, {
      is_verified: true,
      account_verified_at: serverTimestamp()
    })
      .then(() => {
        toast.success('Account successfully verified!');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleUnverify = (id) => {
    const driverRef = doc(db, 'drivers', id);

    updateDoc(driverRef, {
      is_verified: false,
      account_verified_at: ''
    })
      .then(() => {
        toast.success('Account now unverified');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleMakeTester = (id, isTester) => {
    const driverRef = doc(db, 'drivers', id);

    updateDoc(driverRef, {
      tester_account: !isTester,
    })
      .then(() => {
        toast.success("Account type updated. User must relogin for changes to take effect.");
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }


  const actions = [
    {
      name: 'View',
      action: handleModalOpen
    },
  ];

  const handleSearch = (value) => {
    setSearchValue(value);
  }


  return (
    <div className='font-admin'>
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-2">
        Drivers
      </h1>
      <Toolbar searchHandler={handleSearch} searchPlaceholder="Type a driver name and press enter..." />
      <Table headers={tableHeaders} data={driverData} rowActions={actions} highlight={searchValue} />

      <Modal handleClose={handleCloseModal} showModal={showModal} isTester={modal?.tester_account}>
        {
          modal ? (
            <>
              {/* <p>{modal?.user_id}</p> */}
              <h1 className="flex-1 text-xl font-bold mb-2">
                Driver Details
              </h1>
              <div className="-mx-3 md:flex mb-6">

                <Input
                  className="md:w-2/3 px-3"
                  name="Full name"
                  id="full_name"
                  value={modal?.full_name}
                  placeholder="Driver full name"
                />
                <div className="">
                  <Label>STATUS</Label>
                  {
                    modal?.is_verified == true && <Chip text="Verified" type="success" />
                  }
                  {
                    modal?.is_verified == 'pending' && <Chip text="pending" type="warning" />
                  }
                  {
                    modal?.is_verified == false && <Chip text="unverified" type="danger" />
                  }
                </div>
              </div>
              <div className="-mx-3 md:flex mb-6">
                <Input
                  className="md:w-1/2 px-3"
                  name="Mobile Number"
                  id="mobile_number"
                  value={modal?.mobile_number}
                  placeholder="Driver full name"
                // disabled
                />
                <Input
                  className="md:w-1/2 px-3"
                  name="email"
                  id="email"
                  value={modal?.email}
                  placeholder="No email address"
                />
              </div>
              <div className="-mx-3 md:flex mb-6">
                <Input
                  name="address"
                  id="address"
                  value={modal?.address}
                  placeholder="Driver address"
                />
              </div>
              <div className="-mx-3 md:flex mb-6">

                <Input
                  className="md:w-1/2 px-3"
                  id="account_created_at"
                  value={modal?.account_created_at?.toDate()}
                  placeholder="No email address"
                />
                <Input
                  className="md:w-1/2 px-3"
                  id="account_verified_at"
                  value={modal?.account_verified_at && modal?.account_verified_at.toDate()}
                  placeholder="Not yet verified"
                />
              </div>

              <div className="flex -mx-3">
                <div className="md:w-1/2 px-3">
                  <Label>Submitted Documents and IDs</Label>
                  {
                    modal?.verification_files ? Object.entries(modal?.verification_files).map(file => {
                      return (
                        <>
                          {
                            file[1] && (
                              <div className="flex justify-between mb-1 hover:bg-gray-100">
                                <p className="capitalize p-2">{file[0].split(/(?=[A-Z])/).join(' ')}</p>
                                <Button variant="outline" color="blue" onClick={() => setImage(file[1])}>View</Button>
                              </div>
                            )
                          }
                        </>
                      )
                    }) : <p className="text-gray-400">No submitted documents yet.</p>
                  }
                </div>
                <div className="md:w-1/2 px-3">
                  <Label>Actions</Label>

                  {
                    !modal.is_verified || modal.is_verified == 'pending' ? (
                      <button className='btn btn-info' onClick={() => handleVerify(modal?.user_id)}>Verify Account</button>
                    ) : (
                      <button className='btn btn-error' onClick={() => handleUnverify(modal?.user_id)}>Unverify Account</button>
                    )
                  }
                  <br />
                  <button className='btn btn-outline btn-secondary mt-4' onClick={() => handleMakeTester(modal?.user_id, modal?.tester_account)}>Toggle tester account</button>
                </div>
              </div>
            </>
          ) : (
            <Loading className="max-h-10 max-w-10 self-center" />
          )
        }


      </Modal>
      {
        lastKey != '' || lastKey.hasOwnProperty('seconds') ? (
          <button className="btn btn-secondary mt-5" disabled={nextDataLoading} onClick={() => fetchMoreData()}>
            {
              nextDataLoading ? 'Loading...' : 'Load more'
            }
          </button>
        ) : (
          <div className='mt-2 text-gray-300'>No more results</div>
        )
      }
      <Lightbox handleClose={() => { setImage(null) }} visible={image} images={[image]} />
      {/* <SubscriptionModal handleClose={() => setShowSubscriptionModal(false)} showModal={showSubscriptionModal} /> */}
    </div>
  )
}

export default Drivers;