import React from "react"
import { Router, Redirect } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Login from "../app/login"
import Users from "../app/users"
import Drivers from "../app/drivers"
import DriverTracker from "../app/driverTrack"
import Settings from "../app/settings"
import { Helmet } from "react-helmet"
import Cancellations from "../app/cancellations"
import ReportedDrivers from "../app/reported"
// import components here

const App = () => {



  return (
    <div>

      <Helmet>
        <title>Pinbuggy Admin</title>
      </Helmet>
      <Router>
        <Login path="/app" />
        <PrivateRoute path="/app/users" component={Users} />
        <PrivateRoute path="/app/drivers" component={Drivers} />
        <PrivateRoute path="/app/driver-tracker" component={DriverTracker} />
        <PrivateRoute path="/app/settings" component={Settings} />
        <PrivateRoute path="/app/cancellations" component={Cancellations} />
        <PrivateRoute path="/app/reported-drivers" component={ReportedDrivers} />
        {/* <Login path="/app/login" /> */}
      </Router>
    </div>
  )
}

export default App